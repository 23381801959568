import { useState } from 'react';
import moment from 'moment-timezone';
import queries from '../../../queries';
import { useWebLinkQuery } from '../../../weblink-hooks';
import { SearchEvent } from '../../../analytics';

function buildEventFilters(
  courseCode,
  startDate,
  location = null,
  availableSeats = null,
) {
  const filters = [];
  filters.push({
    field: 'courseCode',
    operation: 'eq',
    value: courseCode,
  });
  if (startDate) {
    filters.push({
      field: 'start',
      operation: 'ge',
      value: startDate.toISOString(),
    });
  }
  if (location) {
    filters.push({
      field: 'locationId',
      operation: 'eq',
      value: location,
    });
  }
  if (availableSeats) {
    filters.push({
      field: 'remainingPlaces',
      operation: 'ge',
      value: availableSeats,
    });
  }
  return filters;
}

export const useEventsFetcher = (
  learningPathId,
  courseCode,
  location,
  startDate,
  availableSeats,
  currentTimeZone,
  onEventSearchCompleted,
) => {
  const eventStartDate = startDate
    ? moment.tz(startDate, currentTimeZone)
    : null;
  const filters = buildEventFilters(
    courseCode,
    eventStartDate,
    location,
    availableSeats,
  );

  const [offset, setOffset] = useState(0);
  const { loading, error, data } = useWebLinkQuery(
    queries.learningPath.getPathEventSelectionOptions,
    {
      learningPathId,
      filters,
      offset,
      first: 10,
      orderBy: [
        { field: 'start', direction: 'asc' },
        { field: 'locationName', direction: 'asc' },
      ],
    },
  );

  const loaded = !loading && !error && !!data;
  const totalRecords = loaded
    ? data.learningPathEventSelectionOptions.pageInfo.totalRecords
    : 0;
  const events = loaded
    ? data.learningPathEventSelectionOptions.edges.map(
        ({ node: { event } }) => event,
      )
    : [];

  if (!loading && !error) {
    onEventSearchCompleted(
      SearchEvent.fromLearningPathObjectivesSearch({
        filters,
        resultCount: totalRecords,
      }),
    );
  }
  return {
    loading,
    error,
    events,
    totalEvents: totalRecords,
    eventOffset: offset,
    setEventOffset: setOffset,
  };
};
