import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  useTypedFormValues,
  Form,
  StaticInput,
  Row,
  Col,
} from '@administrate/piston-ux';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';

import inject from '../inject';
import { STORES } from '../../constants/index';
import withCurrency from '../withCurrency';
import LegacyModal from '../../components/ModalOutlet/LegacyModal';
import {
  formatDate,
  formatDateWithTime,
  formatTimeWithTZ,
} from '../../utils/formatDateTime';
import {
  PickerModalTotal,
  PriceLevelPicker,
  ProductImage,
  QuantityPicker,
} from '../Modals/ProductPickerModal';
import { DateTime } from "luxon";
import { hasEventStartedByISOString } from '../../utils/hasEventStarted';

const { STORE_EVENT_PICKER, STORE_CART } = STORES;

const formatDateRange = ({ start, end }) =>
  `${formatDateWithTime(start)} - ${formatTimeWithTZ(end)}`;

const EventModal = ({
  formatCurrency,
  t,
  [STORE_EVENT_PICKER]: {
    action,
    close: closeModal,
    courseName,
    isLoading,
    selectedEvent,
    show: showModal,
    title,
    eventPriceLevels: priceLevels,
    imageUrl,
  },
  [STORE_CART]: { isLoading: isCartLoading },
}) => (
  <LegacyModal
    show={showModal}
    onCancel={closeModal}
    title={t(title)}
    submitLabel={t(title)}
    onSubmit={() => action(selectedEvent)}
    disabled={
      !selectedEvent.id ||
      isLoading ||
      isCartLoading ||
      !selectedEvent.quantity ||
      (priceLevels.length > 0 && !selectedEvent.priceLevel)
    }
    isLoading={isLoading}
  >
    <div className="selected-event">
      <Row>
        <Col xs={12} className="mb-3">
          <div className="event">
            <div className="event-details">
              <ProductImage imageUrl={imageUrl} />
              <EventName />
            </div>
          </div>
        </Col>
      </Row>
      {priceLevels.length > 1 ? (
        <>
          <Row>
            <Col xs={12} className="hidden-md hidden-lg hidden-xl">
              <CourseName courseName={courseName} />
            </Col>
            <Col md={6}>
              <LocationSelect />
            </Col>
            <Col md={6}>
              <TimeSelect />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PriceLevelSelect formatCurrency={formatCurrency} />
            </Col>
            <Col md={2}>
              <QuantityInput />
            </Col>
            <Col md={2} mdOffset={1}>
              <TotalValue formatCurrency={formatCurrency} />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col xs={12} className="hidden-md hidden-lg hidden-xl">
            <CourseName courseName={courseName} />
          </Col>
          <Col md={4}>
            <LocationSelect />
          </Col>
          <Col md={4}>
            <TimeSelect />
          </Col>
          <Col md={2}>
            <QuantityInput />
          </Col>
          <Col md={2}>
            <TotalValue formatCurrency={formatCurrency} />
          </Col>
        </Row>
      )}
    </div>
  </LegacyModal>
);

EventModal.propTypes = {
  formatCurrency: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_EVENT_PICKER, STORE_CART)(withCurrency(observer(EventModal))),
);

const Name = ({ t, [STORE_EVENT_PICKER]: { courseName, selectedEvent } }) => (
  <div className="title hidden-xs hidden-sm">
    {selectedEvent.eventName ? selectedEvent.eventName : courseName}
    {selectedEvent.id && (
      <div className="event-places-left">
        {`${t('weblink:placesRemaining')}: ${
          selectedEvent.remainingPlaces !== null
            ? selectedEvent.remainingPlaces
            : t('weblink:unlimited')
        }`}
      </div>
    )}
  </div>
);

Name.propTypes = {
  t: PropTypes.func.isRequired,
};

const EventName = withTranslation()(inject(STORE_EVENT_PICKER)(observer(Name)));

const Location = ({
  t,
  [STORE_EVENT_PICKER]: {
    eventLocations: locations,
    filterEvents,
    isLoading,
    filterCriteria,
  },
}) => {
  const options = locations.map(x => ({ label: x, value: x }));

  const formValues = useTypedFormValues({
    location: filterCriteria.location,
  });

  return (
    <Form values={formValues}>
      <Select
        name="location"
        label={t('weblink:location')}
        options={options}
        disabled={isLoading}
        onChange={value => filterEvents({ key: 'location', value })}
        isClearable={false}
        valid={value => !!value}
      />
    </Form>
  );
};

Location.propTypes = {
  t: PropTypes.func.isRequired,
};

const LocationSelect = withTranslation()(
  inject(STORE_EVENT_PICKER)(observer(Location)),
);

const Time = ({
  t,
  [STORE_EVENT_PICKER]: {
    eventDates: dates,
    filterEvents,
    isLoading,
    selectedEvent,
  },
}) => {
  const options = dates.map(({ id, range, deliveryMethod, eventFromAPI }) => ({
    value: id,
    label:
      range && range.start && range.end
        ? formatDateRange(range)
        : (
            deliveryMethod === 'lms' && hasEventStartedByISOString(eventFromAPI.start, eventFromAPI.timeZoneName)
              ? t('weblink:uponEnrolment')
              : formatDate(DateTime.fromISO(eventFromAPI.start))
        ),
  }));

  const formValues = useTypedFormValues({
    id: selectedEvent.id,
  });

  return (
    <Form values={formValues}>
      <Select
        name="id"
        label={t('weblink:when')}
        options={options}
        disabled={isLoading}
        onChange={value => filterEvents({ key: 'id', value })}
        isClearable={false}
        valid={value => !!value}
      />
    </Form>
  );
};

Time.propTypes = {
  t: PropTypes.func.isRequired,
};

const TimeSelect = withTranslation()(
  inject(STORE_EVENT_PICKER)(observer(Time)),
);

const PriceLevel = ({
  formatCurrency,
  [STORE_EVENT_PICKER]: {
    eventPriceLevels: priceLevels,
    selectedEvent,
    filterEvents,
    isLoading,
  },
}) => (
  <PriceLevelPicker
    formatCurrency={formatCurrency}
    initialPriceLevel={selectedEvent.priceLevel}
    priceLevels={priceLevels}
    onPriceLevelChange={priceLevel =>
      filterEvents({
        key: 'priceLevel',
        value: priceLevel,
      })
    }
    isLoading={isLoading}
  />
);

PriceLevel.propTypes = {
  formatCurrency: PropTypes.func.isRequired,
};

const PriceLevelSelect = inject(STORE_EVENT_PICKER)(observer(PriceLevel));

const Quantity = ({
  [STORE_EVENT_PICKER]: { changeQuantity, isLoading, selectedEvent },
}) => (
  <QuantityPicker
    changeQuantity={changeQuantity}
    isLoading={isLoading}
    selectedProduct={selectedEvent}
    max={
      selectedEvent &&
      selectedEvent.remainingPlaces !== null &&
      selectedEvent.remainingPlaces !== undefined
        ? selectedEvent.remainingPlaces
        : 500
    }
  />
);

Quantity.propTypes = {};

const QuantityInput = inject(STORE_EVENT_PICKER)(observer(Quantity));

const Total = ({
  formatCurrency,
  [STORE_EVENT_PICKER]: { selectedEvent, eventPriceLevels: priceLevels },
}) => (
  <PickerModalTotal
    formatCurrency={formatCurrency}
    selectedProduct={selectedEvent}
    priceLevels={priceLevels}
  />
);

Total.propTypes = {
  formatCurrency: PropTypes.func.isRequired,
};

const TotalValue = inject(STORE_EVENT_PICKER)(observer(Total));

const CourseNameComponent = ({ t, courseName }) => {
  const formValues = useTypedFormValues({
    name: courseName,
  });

  return (
    <Form values={formValues}>
      <StaticInput label={t('weblink:course')} name="name" />
    </Form>
  );
};

CourseNameComponent.propTypes = {
  t: PropTypes.func.isRequired,
  courseName: PropTypes.string.isRequired,
};

export const CourseName = withTranslation()(CourseNameComponent);
