import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { t } from 'i18next';
import {
  formatDateWithTime,
  formatDateWithTimeAndTZ,
  formatTimeWithTZ,
} from '../utils/formatDateTime';
import { hasEventStartedByISOString } from '../utils/hasEventStarted';

const formatDates = ({ event }) => {
  if (!event.start) {
    return { start: '', end: null };
  }

  const zone = event.timeZoneName;
  const start = DateTime.fromISO(event.start, { zone });

  if (!event.end) {
    return { start: formatDateWithTimeAndTZ(start), end: null };
  }

  const end = DateTime.fromISO(event.end, { zone });
  const runsOverMultipleDays = end.startOf('day') > start.startOf('day');

  if (runsOverMultipleDays) {
    return {
      start: formatDateWithTime(start),
      end: formatDateWithTimeAndTZ(end),
    };
  }

  return {
    start: `${formatDateWithTime(start)} - ${formatTimeWithTZ(end)}`,
    end: null,
  };
};

export const EventDateRange = ({ event }) => {
  const { start, end } = formatDates({ event });

  if (
    event.deliveryMethod &&
    event.deliveryMethod === 'lms' &&
    hasEventStartedByISOString(event.start, event.timeZoneName)
  ) {
    return <>{t('weblink:uponEnrolment')}</>;
  }

  return (
    <>
      {start}
      {end && (
        <span className="pl-1">
          - <br />
          {end}
        </span>
      )}
    </>
  );
};

EventDateRange.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    timeZoneName: PropTypes.string,
    deliveryMethod: PropTypes.string,
  }).isRequired,
};
