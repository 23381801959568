import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from '@administrate/piston';
import {
  Checkbox,
  CustomFieldInput,
  Form,
  Input,
} from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';

import inject from '../../inject';
import { STORES } from '../../../constants';
import { CheckoutPaymentButton } from '../../../components/Checkout/Payment/PaymentButton';

const { STORE_CHECKOUT, STORE_CART } = STORES;

const PaymentButton = ({
  [STORE_CART]: { isApplyPromotionCodeLoading },
  [STORE_CHECKOUT]: { isLoading, checkoutWithInvoice },
  values,
  invoiceAttributeDefinitions,
  validateBillingEmail,
}) => {
  const onContinue = () => {
    const paymentAttributes = [];
    invoiceAttributeDefinitions.forEach(attributeDefinition => {
      const paymentAttribute = {
        definitionKey: attributeDefinition.key,
        value: values[attributeDefinition.key],
      };
      paymentAttributes.push(paymentAttribute);
    });
    checkoutWithInvoice(
      { billingEmail: values.billingEmail },
      paymentAttributes,
    );
  };

  return (
    <CheckoutPaymentButton
      onClick={onContinue}
      // eslint-disable-next-line no-unneeded-ternary
      disabled={
        !(validateBillingEmail(values.billingEmail) === true) ||
        isApplyPromotionCodeLoading ||
        isLoading
      }
    />
  );
};

PaymentButton.propTypes = {
  values: PropTypes.shape({}).isRequired,
  invoiceAttributeDefinitions: MobxPropTypes.observableArray.isRequired,
  validateBillingEmail: PropTypes.func.isRequired,
};

export const InvoicePaymentButton = withTranslation()(
  inject(STORE_CHECKOUT, STORE_CART)(observer(PaymentButton)),
);

const PaymentInfo = ({
  t,
  [STORE_CART]: {
    cart: { buyerDetails },
  },
  values,
  invoiceAttributeDefinitions,
  validateBillingEmail,
}) => {
  const [previousEmailValue, setPreviousEmailValue] = useState(
    buyerDetails.billingEmailAddress,
  );
  const onUseBookerEmailAddressChange = checked => {
    if (checked) {
      setPreviousEmailValue(values.billingEmail);
    }
    values.set(
      'billingEmail',
      checked ? buyerDetails.email : previousEmailValue,
    );
  };

  return (
    <Container.Row className="mt-3">
      <Container.Col xs={12}>
        <p>{t('weblink:invoicePaymentInfo')}</p>
        <Form values={values}>
          <Checkbox
            name="useBookerEmailAddress"
            description={t('weblink:useBookerEmailAddress')}
            onChange={onUseBookerEmailAddressChange}
          />
          <Input
            type="email"
            name="billingEmail"
            label={t('weblink:billingEmail')}
            valid={validateBillingEmail}
          />
          {invoiceAttributeDefinitions.map(field => (
            <CustomFieldInput
              key={field.key}
              label={field.label}
              name={field.key}
              type={field.type}
              options={field.options}
            />
          ))}
        </Form>
      </Container.Col>
    </Container.Row>
  );
};

PaymentInfo.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  invoiceAttributeDefinitions: MobxPropTypes.observableArray.isRequired,
  validateBillingEmail: PropTypes.func.isRequired,
};

export default withTranslation()(inject(STORE_CART)(observer(PaymentInfo)));
