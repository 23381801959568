import { DateTime } from 'luxon';

export const hasEventStartedByISOString = (start, timeZoneName) => {
  const timeNow = DateTime.now().setZone(timeZoneName);
  const startTime = DateTime.fromISO(start, { zone: timeZoneName });

  return startTime <= timeNow;
};

export const hasEventStartedByDateObject = startDateTimeObj => {
  const timeNow = DateTime.now().setZone(startDateTimeObj.zoneName);

  return startDateTimeObj <= timeNow;
};
