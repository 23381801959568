import { observable, decorate, runInAction, action, computed } from 'mobx';

import BaseStore from './baseStore';
import formatCurrency from '../utils/formatCurrency';
import { extractNodes } from '../utils/graphqlMappers';

class StoreStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);

    runInAction(() => {
      this.storeDetails = null;
      this.storeDetailsIsLoading = true;
    });
  }

  formatCurrency = amount =>
    formatCurrency(amount, this.currency.symbol, this.currency.code);

  formatCurrencyForCalculation = amount =>
    formatCurrency(amount, this.currency.symbol, this.currency.code, false);

  get paymentProviders() {
    return this.storeDetails?.paymentMethods ?? [];
  }

  get currency() {
    return this.storeDetails?.currency ?? { symbol: null, code: null };
  }

  get region() {
    return this.storeDetails?.region ?? { id: null, code: null };
  }

  get defaultErrorMessage() {
    return this.storeDetails?.defaultErrorMessage ?? null;
  }

  get terms() {
    return this.storeDetails?.terms ?? [];
  }

  get taxInclusive() {
    return this.storeDetails?.taxInclusive ?? false;
  }

  get isTaxInclusiveOnly() {
    return this.storeDetails?.isTaxInclusiveOnly ?? false;
  }

  get confirmEmails() {
    return this.storeDetails?.confirmEmails ?? false;
  }

  get hidePrices() {
    return this.storeDetails?.hidePrices ?? false;
  }

  get cartlessCheckout() {
    return this.storeDetails?.cartlessCheckout ?? false;
  }

  get requireLearnerDetails() {
    return this.storeDetails?.requireLearnerDetails ?? true;
  }

  get hideCompanyFieldForBooker() {
    return this.storeDetails?.hideCompanyFieldForBooker ?? false;
  }

  get requireBookerBillingAddress() {
    return this.storeDetails?.requireBookerBillingAddress ?? false;
  }

  get giftVouchersEnabled() {
    return !!this.storeDetails?.giftVoucherConfiguration;
  }

  get giftVoucherConfigurationId() {
    return this.storeDetails?.giftVoucherConfiguration?.id ?? null;
  }

  get giftVoucherConfigDescription() {
    return (
      this.storeDetails?.giftVoucherConfiguration?.productDescription ?? null
    );
  }

  get giftVoucherImageUrl() {
    return this.storeDetails?.giftVoucherConfiguration?.imageUrl ?? null;
  }

  get priceLevels() {
    return this.storeDetails?.priceLevels ?? [];
  }

  get giftVoucherEmailTemplates() {
    return extractNodes(this.storeDetails?.giftVoucherEmailTemplates);
  }

  get allowTrainingRequests() {
    return this.storeDetails?.trainingRequestWorkflow?.enabled ?? false;
  }

  get catalogueOverrideUrl() {
    return this.storeDetails?.overrideUrls?.catalogue ?? null;
  }

  get cartOverrideUrl() {
    return this.storeDetails?.overrideUrls?.cart ?? null;
  }

  get postalCountries() {
    return this.storeDetails?.postalCountries ?? [];
  }

  get multiRegion() {
    return this.storeDetails?.multiRegion ?? false;
  }

  updateStoreDetails = action(store => {
    this.storeDetails = store;
    this.storeDetailsIsLoading = false;
  });
}

decorate(StoreStore, {
  storeDetails: observable,
  storeDetailsIsLoading: observable,
  paymentProviders: computed,
  taxInclusive: computed,
  isTaxInclusiveOnly: computed,
  currency: computed,
  region: computed,
  defaultErrorMessage: computed,
  terms: computed,
  confirmEmails: computed,
  hidePrices: computed,
  cartlessCheckout: computed,
  requireLearnerDetails: computed,
  giftVouchersEnabled: computed,
  giftVoucherConfigurationId: computed,
  giftVoucherConfigDescription: computed,
  giftVoucherEmailTemplates: computed,
  postalCountries: computed,
  priceLevels: computed,
  allowTrainingRequests: computed,
});

export default StoreStore;
