import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { DateTime } from 'luxon';
import withCurrency from '../../containers/withCurrency';
import { formatDate } from '../../utils/formatDateTime';
import { hasEventStartedByDateObject } from '../../utils/hasEventStarted';

const getItemName = (t, cartItem) =>
  cartItem.giftVoucherId ? t('weblink:giftVoucher') : cartItem.course;

const getCourseSummary = (t, cartItem) =>
  !cartItem.pathId && !cartItem.giftVoucherId
    ? `${cartItem.location?.name}, ${
        cartItem.deliveryMethod === 'lms'
          ? hasEventStartedByDateObject(cartItem.startDateTime)
            ? t('weblink:uponEnrolment')
            : // This is required to show the local date rather than the date in the timezone that the LMS event has been set up in.
              formatDate(DateTime.fromMillis(cartItem.startDateTime.ts))
          : formatDate(cartItem.startDateTime)
      }`
    : '';

const getChildItemDescription = childItem =>
  childItem.productOption && childItem.productOption.description
    ? childItem.productOption.description
    : '';

const getChildItemAmount = (childItem, formatCurrencyForCalculation) =>
  childItem.totalAmount
    ? formatCurrencyForCalculation(childItem.totalAmount)
    : '';

const getItemPriceLevelName = priceLevelName =>
  priceLevelName ? `(${priceLevelName})` : '';

const CourseRows = ({ cartContent, formatCurrencyForCalculation, t }) => (
  <div className="SummaryCard__content">
    {cartContent.map((item, index) => (
      <Fragment key={item.id}>
        <div className="item-row">
          <p className="font-weight-bold mb-3">{getItemName(t, item)}</p>
          <p>{getCourseSummary(t, item)}</p>

          <SummaryRow
            label={t('weblink:price')}
            value={`${formatCurrencyForCalculation(
              item.priceLevel.amount,
            )} ${getItemPriceLevelName(item.priceLevel.name)}`}
          />
          <SummaryRow
            label={t('weblink:quantity')}
            value={`${item.quantity}`}
          />
          <div className="text-right my-2">
            <SummaryRow
              label={t('weblink:total')}
              value={`${formatCurrencyForCalculation(item.subTotalAmount)}`}
            />
          </div>
          {item.childItems && (
            <ChildItemRows
              t={t}
              childItems={item.childItems}
              formatCurrencyForCalculation={formatCurrencyForCalculation}
            />
          )}
          {cartContent.length !== index + 1 && <hr />}
        </div>
      </Fragment>
    ))}
    <hr />
  </div>
);

const SummaryRow = ({ label, value }) => (
  <p>
    <span className="font-weight-bold mr-2">{label}:</span>
    {value}
  </p>
);

const ChildItemRows = ({ t, childItems, formatCurrencyForCalculation }) =>
  childItems.map(childItem => (
    <div key={childItem.id}>
      <div className="SummaryCard__courses_course_title_label">
        {getChildItemDescription(childItem)}
      </div>
      <SummaryRow
        label={t('weblink:price')}
        value={`${getChildItemAmount(childItem, formatCurrencyForCalculation)}`}
      />
    </div>
  ));

CourseRows.propTypes = {
  t: PropTypes.func.isRequired,
  cartContent: MobxPropTypes.arrayOrObservableArray.isRequired,
  formatCurrencyForCalculation: PropTypes.func.isRequired,
};

SummaryRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ChildItemRows.propTypes = {
  t: PropTypes.func.isRequired,
  childItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formatCurrencyForCalculation: PropTypes.func.isRequired,
};

export default withTranslation()(withCurrency(CourseRows));
