import { DateTime } from 'luxon';
import { decorate, runInAction, observable, reaction, computed } from 'mobx';
import { every as _every } from 'lodash';

import Learner from './Learner';
import formatCurrency from '../utils/formatCurrency';
import GiftVoucherRecipient from './GiftVoucherRecipient';
import { MAX_SEATS } from '../constants';
import { mapValuesArrayToObject } from '../utils/customFields';
import { extractNodes } from '../utils/graphqlMappers';

/* eslint-disable-next-line */
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export class CartItem {
  constructor(props) {
    const {
      item: {
        id,
        quantity,
        unitAmount,
        subTotalAmount,
        totalTaxAmount,
        totalAmount,
        tax,
        reserved,
        isExpired,
        learnerDetails = [],
        pointOfSaleDefinitions,
        productOption: {
          __typename,
          id: pathOrEventId,
          code,
          name,
          location,
          start,
          end,
          remainingPlaces,
          course,
          imageUrl,
          linkedItem,
          categories,
          isFeatured,
          learningTags,
          accountAssociations,
          deliveryMethod,
        },
        objectives = [],
        childItems = [],
        priceLevel,
      },
      isSplitProduct,
      buyerDetails,
      updateItemQuantity,
    } = props;

    const courseId = course ? course.id : null;
    const pathId = __typename === 'LearningPath' ? pathOrEventId : null;
    const isPath = __typename === 'LearningPath';
    let giftVoucherId = null;
    let isGiftVoucher = false;
    let giftVoucherConfigLinkedItem = null;
    let giftVoucherRecipientDetails = null;

    const linkedItemHasPriceDetails = linkedItemPriceDetail =>
      linkedItemPriceDetail &&
      linkedItemPriceDetail.price &&
      linkedItemPriceDetail.price.amount &&
      linkedItemPriceDetail.price.financialUnit &&
      linkedItemPriceDetail.price.financialUnit.symbol;

    if (__typename === 'GiftVoucherConfiguration') {
      // eslint-disable-line
      giftVoucherId = id;
      isGiftVoucher = true;
      if (linkedItemHasPriceDetails(linkedItem)) {
        giftVoucherConfigLinkedItem = {
          amount: linkedItem.price.amount,
          symbol: linkedItem.price.financialUnit.symbol,
          id: linkedItem.id,
          name: linkedItem.name,
          description: linkedItem.description,
        };
        giftVoucherConfigLinkedItem.cost = formatCurrency(
          giftVoucherConfigLinkedItem.amount,
          giftVoucherConfigLinkedItem.symbol,
        );
      }
      if (props.item && props.item.giftVoucherRecipientDetails) {
        giftVoucherRecipientDetails = new GiftVoucherRecipient({
          email: props.item.giftVoucherRecipientDetails.email,
          confirmedEmail: props.item.giftVoucherRecipientDetails.email,
          name: props.item.giftVoucherRecipientDetails.name,
          postalAddressLineOne:
            props.item.giftVoucherRecipientDetails.postalAddressLineOne,
          postalAddressLineTwo:
            props.item.giftVoucherRecipientDetails.postalAddressLineTwo,
          postalAddressTown:
            props.item.giftVoucherRecipientDetails.postalAddressTown,
          postalAddressPostcode:
            props.item.giftVoucherRecipientDetails.postalAddressPostcode,
          postalAddressCountryName:
            props.item.giftVoucherRecipientDetails.postalAddressCountry,
          message: '',
        });
      } else {
        giftVoucherRecipientDetails = new GiftVoucherRecipient({
          email: buyerDetails.email,
          name: `${buyerDetails.firstName} ${buyerDetails.lastName}`,
          confirmedEmail: buyerDetails.email,
        });
      }
    }

    const remainingPlacesFromObjectives = [];
    objectives.forEach(objective => {
      const { event } = objective;
      if (event && event.remainingPlaces !== null) {
        remainingPlacesFromObjectives.push(event.remainingPlaces);
      }
    });

    const pathRemainingPlaces =
      remainingPlacesFromObjectives.length !== 0
        ? Math.max(Math.min(...remainingPlacesFromObjectives), 0)
        : null;

    runInAction('Cart Item constructor', () => {
      this.id = id;
      this.code = code;
      this.quantity = Number(quantity);
      this._previousQuantity = this.quantity;
      this.location = {
        id: location?.id ?? null,
        name: location?.name ?? null,
      };
      this.unitAmount = unitAmount;
      this.subTotalAmount = subTotalAmount;
      this.totalAmount = totalAmount;
      this.unitTaxAmount = (Number(totalTaxAmount ?? 0) / 2).toFixed(2);
      this.totalTaxAmount = totalTaxAmount;
      this.tax = tax;
      this.course = name;
      this.eventId = pathOrEventId;
      this.courseId = courseId;
      this.pathId = pathId;
      this.isPath = isPath;
      this.giftVoucherId = giftVoucherId;
      this.isGiftVoucher = isGiftVoucher;
      this.imageUrl = course ? course.imageUrl : imageUrl;
      this.startDateTime = DateTime.fromISO(start, { setZone: true });
      this.endDateTime = DateTime.fromISO(end, { setZone: true });
      this.remainingPlaces = isPath ? pathRemainingPlaces : remainingPlaces;
      this.updateItemQuantity = updateItemQuantity;
      this.recipientDetails = giftVoucherRecipientDetails;
      this.childItems = childItems;
      this.giftVoucherConfigLinkedItem = giftVoucherConfigLinkedItem;
      this.isExpired = isExpired;
      this.priceLevel = { ...priceLevel, amount: unitAmount };
      this.objectives = objectives;
      this.isReserved = reserved;
      this.usingBookerDetails = !learnerDetails.length && !isSplitProduct;
      this.pointOfSaleDefinitions = pointOfSaleDefinitions;
      this.categories = categories;
      this.isFeatured = isFeatured ?? course?.isFeatured ?? false;
      this.learningTags = extractNodes(learningTags ?? course?.learningTags);
      this.accountAssociations = extractNodes(
        accountAssociations ?? course?.accountAssociations,
      );
      this.deliveryMethod = deliveryMethod ?? null;

      const learners = [];

      if (!learnerDetails.length && this.usingBookerDetails) {
        const itemApplicablePoSDefinitionKeys = pointOfSaleDefinitions.map(
          ({ key }) => key,
        );
        const applicableCustomFieldValues = buyerDetails.customFieldValues.filter(
          ({ definitionKey, value }) =>
            itemApplicablePoSDefinitionKeys.includes(definitionKey) &&
            value !== null,
        );
        learners.push(
          new Learner({
            id: 1,
            email: buyerDetails.email,
            confirmedEmail: buyerDetails.email,
            firstName: buyerDetails.firstName,
            lastName: buyerDetails.lastName,
            customFieldValues: mapValuesArrayToObject(
              applicableCustomFieldValues,
            ),
          }),
        );
      }

      for (let i = learners.length; i < quantity; i += 1) {
        const savedLearner = i < learnerDetails.length;
        const learner = new Learner({
          id: i + 1,
          email: savedLearner ? learnerDetails[i].email : '',
          confirmedEmail: savedLearner ? learnerDetails[i].email : '', // Email already confirmed for a savedLearner
          firstName: savedLearner ? learnerDetails[i].firstName : '',
          lastName: savedLearner ? learnerDetails[i].lastName : '',
          customFieldValues:
            savedLearner && learnerDetails[i].attributes
              ? mapValuesArrayToObject(learnerDetails[i].attributes)
              : {},
        });
        const buyerLearner =
          savedLearner && learnerDetails[i].email === buyerDetails.email; // TODO: Is this comparison okay?
        if (buyerLearner) {
          learners.unshift(learner);
          this.usingBookerDetails = true;
        } else {
          learners.push(learner);
        }
      }
      this.learners = learners;

      // Quantity change reaction
      this.quantityDisposer = reaction(
        () => this.quantity,
        async newQuantity =>
          // this._previousQuantity workaround (MobX >= 6 injects previousQuantity as 2nd param to this function)
          this.updateItemQuantity(this, newQuantity, this._previousQuantity),
        {
          name: 'cartQuantityChangeReaction',
          equals: (originalQuantity, newQuantity) =>
            originalQuantity === newQuantity,
        },
      );
    });
  }

  get isDiverseEmails() {
    const emails = this.learners.map(learner => learner.email).filter(Boolean);
    return new Set(emails).size === emails.length;
  }

  isComplete(customFieldDefinitions, requireLearnerDetails = null) {
    if (!customFieldDefinitions) {
      return false;
    }

    if (this.isGiftVoucher) {
      return this.recipientDetails.isValid();
    }

    return _every(this.learners, learner =>
      learner.isValid(customFieldDefinitions, requireLearnerDetails),
    );
  }

  get maxBookableQuantity() {
    if (this.remainingPlaces === null) {
      return MAX_SEATS;
    }

    // Reserving a place means the remainingPlaces will have changed, so take those into account
    if (this.isReserved) {
      return this.quantity + this.remainingPlaces;
    }

    return this.remainingPlaces;
  }
}

class BuyerDetails {
  constructor({
    email,
    firstName,
    lastName,
    companyName,
    billingAddress,
    billingEmailAddress,
    // Below is not stored at API yet
    confirmedEmail,
    phone,
    address1,
    address2,
    address,
    zip,
    country,
    customFieldValues,
  }) {
    runInAction('BuyerDetails constructor', () => {
      this.email = email;
      this.firstName = firstName;
      this.lastName = lastName;
      this.confirmedEmail = confirmedEmail;
      this.phone = phone;
      this.company = companyName || undefined;
      this.billingAddress = billingAddress || undefined;
      this.billingEmailAddress = billingEmailAddress || undefined;
      this.address1 = address1;
      this.address2 = address2;
      this.address = address;
      this.zip = zip;
      this.country = country;
      this.customFieldValues = customFieldValues;
    });
  }

  get isEmailValid() {
    return emailRegex.test(this.email);
  }

  get isEmailConfirmed() {
    return this.confirmedEmail && this.confirmedEmail === this.email;
  }

  get isFirstNameValid() {
    return !!this.firstName;
  }

  get isLastNameValid() {
    return !!this.lastName;
  }

  get isValid() {
    return (
      this.isEmailValid &&
      this.isEmailConfirmed &&
      this.isFirstNameValid &&
      this.isLastNameValid
    );
  }
}

class Cart {
  constructor({
    id = null,
    items = [],
    buyerDetails = {},
    state,
    requiresReviewBeforePurchase,
    reservationsValidUntil,
    promotionalCode,
    giftVoucherApplications = [],
    price = {
      grandTotal: 0,
      subTotal: 0,
      taxes: [],
    },
    region = {},
    currency = {},
  }) {
    runInAction('Cart constructor', () => {
      this.id = id;
      this.reservationsValidUntil = reservationsValidUntil;
      this.promotionalCode = promotionalCode;
      this.giftVoucherApplications = giftVoucherApplications;
      this.state = state;
      this.requiresReviewBeforePurchase = requiresReviewBeforePurchase;
      this.price = {
        ...price,
        taxTotal: price.taxes
          .reduce(
            (runningTotal, { totalAmount }) =>
              runningTotal + Number(totalAmount),
            0,
          )
          .toFixed(2),
      };
      this.items = items;
      this.buyerDetails = buyerDetails;
      this.region = region;
      this.currency = currency;
    });
  }

  static fromAPIResponse(apiResponse, { updateItemQuantity }) {
    return new Cart({
      id: apiResponse.id,
      price: apiResponse.price,
      reservationsValidUntil: apiResponse.reservationsValidUntil,
      promotionalCode: apiResponse.promotionalCode,
      giftVoucherApplications: apiResponse.giftVoucherApplications,
      state: apiResponse.state,
      requiresReviewBeforePurchase: apiResponse.requiresReviewBeforePurchase,
      items: apiResponse.items.reduce(
        (prev, curr) => [
          ...prev,
          new CartItem({
            item: curr,
            buyerDetails: apiResponse.buyerDetails,
            isSplitProduct: prev.some(({ eventId, pathId }) =>
              [eventId, pathId].includes(curr.productOption.id),
            ),
            updateItemQuantity,
          }),
        ],
        [],
      ),
      /* Populate confirmedEmail with buyer email here so that the value is saved
      when switching between Booker and Learner input forms.
      The email has already been confirmed and we don't want the buyer to have to reconfirm.
      confirmedEmail is used for front-end validation and so is not sent or returned from the API call. */
      buyerDetails: new BuyerDetails({
        ...apiResponse.buyerDetails,
        confirmedEmail: apiResponse.buyerDetails.email,
      }),
      region: {
        id: apiResponse.region.id,
        code: apiResponse.region.code,
        name: apiResponse.region.name,
      },
      currency: {
        code: apiResponse.currency.code,
        name: apiResponse.currency.name,
        symbol: apiResponse.currency.symbol,
      },
    });
  }

  get isFree() {
    return Number(this.price.payableTotal) === 0;
  }

  get isEmpty() {
    return this.items.length === 0;
  }

  get summaryList() {
    return this.currentItems.map(
      ({ id, quantity, course, imageUrl, isGiftVoucher }) => ({
        itemId: id,
        quantity,
        title: course,
        imageUrl,
        isGiftVoucher,
      }),
    );
  }

  dispose = () => {
    this.items.map(item => item.quantityDisposer());
  };

  get currentItems() {
    return this.items.filter(item => !item.isExpired);
  }

  get expiredItems() {
    return this.items.filter(item => item.isExpired);
  }

  get containsGiftVoucher() {
    return this.items.some(item => item.isGiftVoucher);
  }

  getCartItemById = cartItemId =>
    this.items.find(item => item.id === cartItemId);
}

decorate(CartItem, {
  learners: observable,
  id: observable,
  quantity: observable,
  location: observable,
  unitAmount: observable,
  subTotalAmount: observable,
  totalAmount: observable,
  tax: observable,
  course: observable,
  eventId: observable,
  courseId: observable,
  startDateTime: observable,
  endDateTime: observable,
  remainingPlaces: observable,
  imageUrl: observable,
  recipientDetails: observable,
  isExpired: observable,
  priceLevel: observable,
  pointOfSaleDefinitions: observable,
  deliveryMethod: observable,
});

decorate(Cart, {
  id: observable,
  state: observable,
  items: observable,
  price: observable,
  reservationsValidUntil: observable,
  promotionalCode: observable,
  giftVoucherApplications: observable,
  buyerDetails: observable,
  isEmpty: computed,
  isFree: computed,
  summaryList: computed,
  currentItems: computed,
  expiredItems: computed,
});

decorate(BuyerDetails, {
  email: observable,
  isEmailValid: computed,
  confirmedEmail: observable,
  isEmailConfirmed: computed,
  firstName: observable,
  isFirstNameValid: computed,
  lastName: observable,
  isLastNameValid: computed,
  phone: observable,
  company: observable,
  address1: observable,
  address2: observable,
  address: observable,
  zip: observable,
  country: observable,
  isValid: computed,
  customFieldValues: observable,
});

export default Cart;
